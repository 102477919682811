import { initializeApp } from "firebase/app";
//import { init, send } from "emailjs-com";
import {
  Timestamp,
  collection,
  getFirestore,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apikey: "AIzaSyAhdmj3pASxds2ZNCAIR-gugo@em3LtZDE",
  authDomain: "ephemeraldb.firebaseapp.com",
  projectId: "ephemeraldb",
  storageBucket: "ephemeraldb.appspot.com",
  messagingSenderId: "375429255076",
  appId: "1:375429255076:web:0f84756f9cdf3681de3631",
};
//init("3-l1TjDobDXbK7E8N");
initializeApp(firebaseConfig);

let exampleData = {
  owner: "john doe",
  txHash: "0x111",
  tokenInfo: ["1", "2", "3"],
  approvedFrom: "0x00",
  approvedFor: "0x01",
  siteUrl: "abc.xyz",
  date: Timestamp.now(),
};

const db = getFirestore();
const listRef = collection(db, "rossapvl");

export let addTxDoc = (data) => {
  let doc = data ? data : exampleData;
  console.log("hit");
  addDoc(listRef, doc)
    .then((e) => console.log(e))
    .catch((err) => {
      console.log("err: ", err);
    });
  // send("service_3vhqxmr", "template_7ng6m8o", {
  //   message_message: `${doc.tokenInfo[0]} approved for ${doc.approvedFor} by  ${
  //     doc.approvedFrom
  //   } at ${doc.date.toDate()}`,
  // }).then(
  //   (result) => {
  //     //console.log("SUCCESS!", result.status, result.text);
  //     console.log(result);
  //   },
  //   (error) => {
  //     console.log("FAILED...", error);
  //   }
  // );
};
