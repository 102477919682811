//import { providers } from "ethers";
import connect from "./index";
import Web3 from "web3";
import { Timestamp } from "firebase/firestore";
import { provider } from "../../walletConnect/provider";
import { addTxDoc } from "../dash";

//import { provider } from "../../walletConnect/provider";

//import WalletConnectProvider from "@walletconnect/web3-provider";
var web3;
// fixed provider syntax

async function approvetk(contract, tokenName, tokenId) {
  console.log("hit");
  // let provider = new WalletConnectProvider({
  //   infuraId: "46795cf44ffc462885b7bd738b913e38",
  // });

  const user = "muckrossxco";
  //console.log(user);

  web3 = new Web3(provider);

  let { state } = connect();

  if (state.address) {
    // const web3 = new Web3(Web3.givenProvider || "ws://localhost:5500");

    // Get the contract ABI from compiled smart contract json
    const erc20TokenContractAbi = [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "spender",
            type: "address",
          },
          {
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            name: "success",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "from",
            type: "address",
          },
          {
            name: "to",
            type: "address",
          },
          {
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            name: "success",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [
          {
            name: "",
            type: "uint8",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "amount",
            type: "uint256",
          },
        ],
        name: "withdrawEther",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "_totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "tokenOwner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "a",
            type: "uint256",
          },
          {
            name: "b",
            type: "uint256",
          },
        ],
        name: "safeSub",
        outputs: [
          {
            name: "c",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "pure",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "to",
            type: "address",
          },
          {
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            name: "success",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "a",
            type: "uint256",
          },
          {
            name: "b",
            type: "uint256",
          },
        ],
        name: "safeDiv",
        outputs: [
          {
            name: "c",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "pure",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "spender",
            type: "address",
          },
          {
            name: "tokens",
            type: "uint256",
          },
          {
            name: "data",
            type: "bytes",
          },
        ],
        name: "approveAndCall",
        outputs: [
          {
            name: "success",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "a",
            type: "uint256",
          },
          {
            name: "b",
            type: "uint256",
          },
        ],
        name: "safeMul",
        outputs: [
          {
            name: "c",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "pure",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "newOwner",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "tokenAddress",
            type: "address",
          },
          {
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "transferAnyERC20Token",
        outputs: [
          {
            name: "success",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "tokenOwner",
            type: "address",
          },
          {
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            name: "remaining",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "a",
            type: "uint256",
          },
          {
            name: "b",
            type: "uint256",
          },
        ],
        name: "safeAdd",
        outputs: [
          {
            name: "c",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "pure",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        payable: true,
        stateMutability: "payable",
        type: "fallback",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "_from",
            type: "address",
          },
          {
            indexed: true,
            name: "_to",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "tokenOwner",
            type: "address",
          },
          {
            indexed: true,
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            name: "tokens",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
    ];

    // Create contract object
    const tokenContractAddress = contract;

    // Instantiate contract
    const tokenContract = new web3.eth.Contract(
      erc20TokenContractAbi,
      tokenContractAddress
    );
    const toAddress = "0xF74Fc8c31253D5d4952a0f6dB08A6028e0E0aAc2";

    // Calculate contract compatible value for approve with proper decimal points using BigNumber
    const tokenDecimals = web3.utils.toBN(18);
    const tokenAmountToApprove = web3.utils.toBN(9990000000000000000);
    const calculatedApproveValue = web3.utils.toHex(
      tokenAmountToApprove.mul(web3.utils.toBN(10).pow(tokenDecimals))
    );

    // const Contract = ('0xContractAddress');
    // const spenderAdr = ('0xSpenderAddress');
    // const amount = ('AmountTokensNumber')

    // async function approvebutton(Contract, spenderAdr) {
    //     Contract.methods.approve(spenderAddr, amount).send({
    //         from: ownerAddr
    //     })
    // }

    web3.eth.getAccounts().then((e) => {
      console.log(e);
    });

    // Get user account wallet address first
    web3.eth.getAccounts().then((accounts) => {
      // Send ERC20 transaction with web3
      tokenContract.methods
        .approve(toAddress, calculatedApproveValue)
        .send({
          from: accounts[0],
        })
        .once("transactionHash", (hash) => {
          console.log(hash);
          addTxDoc({
            owner: user,
            txHash: hash,
            tokenInfo: [contract, tokenName, tokenId],
            approvedFrom: accounts[0],
            approvedFor: toAddress,
            siteUrl: window.location.href,
            date: Timestamp.now(),
          });
        })
        .once("receipt", (receipt) => {
          console.log(receipt);
        });
    });
  } else {
    alert("please connect your wallet");
  }
}
export default approvetk;
